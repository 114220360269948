import React, { useEffect, useState, useGlobal } from "reactn";

import { useParams } from 'react-router-dom'
import moment from 'moment';
import { Row, Col, Form, Input, Button, Switch, DatePicker, Card, message, Select, Alert } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { CityPicker, FileInputSingle, GroupPicker } from '../components';

import api from '../service/index'
import { Link } from 'react-router-dom';

import { useCallback } from "react";

import { env } from '../app.config'

const AttendeeDetail = (props) => {

  let params = useParams()
  let history = useHistory()
  let id = params.id !== "add" ? params.id : false;
  let [langs] = useGlobal("langs")

  let newRecord = {
    name: '',
    lastname: '',
    role: 'user',
    groups: [],
    phone: '',
    email: '',
    company: '',
    department: '',
    position: '',
    jobPhone: '',
    facebook: '',
    twitter: '',
    instagram: '',
    linkedin: '',
    skype: '',
    registerNo: '',
    tc: '',
    birthdayString: '',
    lang: langs ? langs.code : "TR",
    isSupportAdmin: ''
  }
  let loginType = env.LOGINTYPE
  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false)
  let [departments, setDepartments] = useState([])
  let [modules] = useGlobal("modules");
  let path = props.location.pathname.split('/')[1];
  let module
  if (modules !== null) {
    [module] = modules.filter(el => el._id === path);
  }


  //onetime run
  useEffect(() => {
    if (modules && id) {
      setLoading(true)
      console.log("id",id)
      api.get("/rest/attendees/" + id).then(({ data: { result, result_message } }) => {
        console.log("result",result);
        if (id) { result.sendSms = false }
        delete result.password;
        setData(result);
        setLoading(false)
      });

      api.get("/rest/reservationDepartments", { pageSize: 10000 }).then(({ data: { result, result_message } }) => {
        setDepartments(result.rows || []);
        setLoading(false)
      });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.name === null || data.name.length === 0)
      errors.name = 'Zorunlu Alan!'

    if (data.email === null || data.email.length === 0)
      errors.email = 'Zorunlu Alan!'
    if (!data.phone || data.phone.length === 0)
      errors.phone = 'Zorunlu Alan!'

    errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);


  let save = async () => {
    setValidationCheck(true)
    let err = validate()
    if (err.hasError) {
      setErrors(err)
      window.scrollTo({ top: 20, behavior: 'smooth' });
    }
    else {

      if (id) {
        data.id=data._id;
        api.put("/rest/isbankAttendees/" + id, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success("Bilgiler güncellendi", 2);
            history.push('/attendees')
          }
          else
            message.error(result_message.message, 2);
        })
      } else {
        api.post("/rest/attendees", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success("Kayıt Başarılı bir şekilde eklendi.", 2);
            history.push('/attendees')
          } else {
            message.error(result_message.message, 2);
          }
        })
      }
    }
  };

  const { Option } = Select;
  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ""}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/attendees">
            <Button type="light" icon={<LeftOutlined />} size="large">GERİ</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card title={id ? "Düzenle" : "Ekle"} loading={loading}>
          <Form layout="horizontal" size={"large"} onFinish={save}>
            <Row direction="row">
              <Col span={12}>
                <Form.Item label="Avatar">
                  <img style={{ background: "lightgray", width: 120 }} src={data.avatar ? data.avatar.url : ""} alt="profile_image"></img>
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Silindi mi?">
                  <Switch checked={!data.deleted ? false : true} checkedChildren="Evet" unCheckedChildren="Hayır" onChange={v => setData({ ...data, deleted: v ? true : false })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Durumu">
                  <Switch checked={data.active ? true : false} checkedChildren="Aktif" unCheckedChildren="Pasif" onChange={v => setData({ ...data, active: v ? true : false })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Eposta" required={loginType === "emailPass" ? true : false} help={errors.email} validateStatus={errors.email ? 'error' : 'success'}>
                  <Input name="email" value={data.email} onChange={e => setData({ ...data, email: e.target.value })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Telefon" required={loginType === "phone" ? true : false} help={errors.phone} validateStatus={errors.phone ? 'error' : 'success'}>
                  <Input name="phone" value={data.phone} onChange={e => setData({ ...data, phone: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="İsim" required help={errors.name} validateStatus={errors.name ? 'error' : 'success'}>
                  <Input name="name" value={data.name} onChange={e => setData({ ...data, name: e.target.value })} />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Yeni Şifre Belirle" help={errors.password} validateStatus={errors.password ? 'error' : 'success'}>
                  <Input name="password" onChange={e => setData({ ...data, password: e.target.value })} />
                  <br /><small>- Kullanıcı Şifresini değiştirmemek için boş bırakınız</small>
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Departman" help={errors.position} validateStatus={errors.position ? 'error' : 'success'}>
                  <Select defaultValue={data.position} onChange={v => setData({ ...data, position: v })} >
                    {departments.map(department => <Option key={department._id} value={department.name}>{department.name}</Option>)}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Şehir:" help={errors.location} validateStatus={errors.location ? 'error' : 'success'}>
                  <CityPicker record={data} setRecord={setData} name="location" mode="single" />
                </Form.Item>
              </Col>

            </Row>

            {/* <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Doğum Günü" help={errors.birthday} validateStatus={errors.birthday ? 'error' : 'success'}>
                  <DatePicker defaultValue={() => moment(data.birthday)} onChange={v => setData({ ...data, birthday: v })} format='DD-MM' showTime={true} />
                  <br /><small>Doğum günü tarihi değiştirilmediği sürece kaydedilmez. Fakat düzenlemelerde içerisinde bulunan gün varsayılan olarak gözükür. </small>
                </Form.Item>
              </Col>
            </Row> */}

            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button type="primary" disabled={loading} htmlType="submit" size="large" block > KAYDET </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default AttendeeDetail;
