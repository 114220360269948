import React, { useEffect, useState } from "reactn";
import { Row, Col, Form, Input, Button, Switch, Card, message, Alert } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import api from '../../service/index'
import { Link } from 'react-router-dom';
import { ImageUrl } from '../../components';


const PageReservationKVKKSettings = (props) => {
    let history = useHistory()
    let id = "reservationKVKKSettings"

    let [data, setData] = useState({});
    let [errors, setErrors] = useState([]);
    let [loading, setLoading] = useState(true);

    //onetime run
    useEffect(() => {
        get();
    }, []);

    let get = async () => {
        if (id) {
            await api.get("/rest/settings/" + id).then(({ data: { result, result_message } }) => {
                setData(result);
                setLoading(false)
            });
        } else {
            setLoading(false)
        }
    }

    let save = async () => {
        data.id = id;
        if (id) {
            api.put("/rest/settings/" + id, data).then(({ data: { result, result_message } }) => {
                if (result_message.type === 'success') {
                    message.success("Bilgiler güncellendi", 2);
                    history.push('/settings/reservationAllSettings')
                }
                else
                    message.error("Kayıt eklenirken bir hata oluştu.", 2);
            })
        } else {
            api.post("/rest/settings", data).then(({ data: { result, result_message } }) => {
                if (result_message.type === 'success') {
                    message.success("Kayıt Başarılı bir şekilde eklendi.", 2);
                    history.push('/settings/reservationAllSettings')
                } else {
                    message.error(result_message.message, 2);
                }
            })
        }
    };

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>KVKK Ayarları</h1>
                </div>
                <div className="list-buttons">
                    <Link to="/settings/reservationAllSettings">
                        <Button type="light" icon={<LeftOutlined />} size="large">GERİ</Button>
                    </Link>
                </div>
            </div>
            <div className="form-wrap">
                {!loading &&
                    <Card title={id ? "KVKK Ayarları Düzenle" : "KVKK Ayarları Ekle"}>
                        <Form layout="horizontal" size={"large"} onFinish={save}>

                            <Row direction="row">
                                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                    <Form.Item label="Kvkk Onayı" help={errors.isKVKK} validateStatus={errors.isKVKK ? 'error' : 'success'}>
                                        <Switch checked={data.isKVKK ? true : false} checkedChildren="Aktif" unCheckedChildren="Pasif" onChange={v => setData({ ...data, isKVKK: v })} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row direction="row">
                                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                    <Form.Item label="Kvkk Metni" help={errors.kvkk} validateStatus={errors.kvkk ? 'error' : 'success'}>
                                        <ImageUrl record={data} setRecord={setData} name="kvkk" />
                                        <Alert message="Lütfen PDF ekleyiniz!" banner />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                    <Form.Item label="Aydınlatma Metni" help={errors.lightingText} validateStatus={errors.lightingText ? 'error' : 'success'}>
                                        <ImageUrl record={data} setRecord={setData} name="lightingText" />
                                        <Alert message="Lütfen PDF ekleyiniz!" banner />
                                    </Form.Item>
                                </Col>
                            </Row>



                            <Row direction="row">
                                <Col span={24}>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" size="large" block > KAYDET </Button>
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Form>
                    </Card>
                }
            </div>
        </div>
    );
};



export default PageReservationKVKKSettings;
