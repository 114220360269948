import React, { useEffect, useState } from "reactn";
import { Form, Button, Card, Popconfirm } from 'antd';
import { LeftOutlined } from "@ant-design/icons";
import { RightOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import api from '../../service/index'
import { Link } from 'react-router-dom';
import qs from 'qs';

const PageReservstionDepartments = () => {
  let [data, setData] = useState({});
  let [loading, setLoading] = useState(true);

  //onetime run
  useEffect(() => {
    get();
  }, []);

  let _params = { page: 1, pageSize: 1000, sort: "order" };
  let get = async () => {
    await api.get(`rest/reservationDepartments?${qs.stringify(_params)}`).then(({ data: { result, result_message } }) => {
      setData(result.rows);
      setLoading(false)
    });
  }

  let deleteRow = async (item_id) => {
    api.delete(`/rest/reservationDepartments/${item_id}`, ({ data }) => { });
    let newData = data.filter(el => el._id !== item_id);
    setData(newData);
  }

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Rezervasyon Ayarları</h1>
        </div>
        <div className="list-buttons">
          <Link to="/settings/pageReservationDepartments/add">
            <Button type="light" icon={<PlusOutlined />} style={{ marginRight: "5px" }} size="large">Yeni Ekle</Button>
          </Link>
          <Link to="/settings/reservationAllSettings">
            <Button type="light" icon={<LeftOutlined />} size="large">GERİ</Button>
          </Link>

        </div>
      </div>
      <div className="form-wrap">

        {!loading &&
          <Card title={"Bölümler"}>
            <Form layout="horizontal" size={"large"}>
              <Card title={""}>
                <div className="table-wrap" style={{ marginTop: 10 }} >
                  <ul className="dragDrop">
                    {data.map((item, index) => {
                      return (
                        <li>
                          <div >
                            <RightOutlined style={{ fontSize: 20 }} />
                            <span className="nameDrag">{item.name}</span>
                          </div>
                          <div className="rightButtons">
                            <Link to={"/settings/pageReservationDepartments/edit/" + item._id}><Button type="primary" size="medium" > Düzenle </Button></Link>
                            <Popconfirm
                              onConfirm={() => deleteRow(item._id)} title="Silmeyi Onaylıyor musunuz?"
                              okText="Onayla" cancelText="Vazgeç">
                              <Button type="danger" icon={<DeleteOutlined />}>
                                Sil
                              </Button>
                            </Popconfirm>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </Card>
            </Form>
          </Card>
        }
      </div>
    </div>
  );
};

export default PageReservstionDepartments;
