import React from 'react';
import {Input,Alert} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import LocationPicker from 'react-location-picker';
import axios from 'axios';
import useInterval from 'react-useinterval';
let key = 'AIzaSyC1NyStYSSVdxiUaiT26GNYb3jkN-W-Uq4';

export default (props) => {
	let {record, setRecord, preview, name, disabled, required} = props;
	let [search,setSearch] = React.useState('');
	let [lastSearch, setLastSearch] = React.useState(record[name] ? record[name].address : '');
	let coordinate = record[name];


	
	let route = async() => {
		let _search = search.length === 0 
			? (record[name] ? record[name].address : '')
			: search;

			
		if(_search != null && _search.length > 3 && _search !== lastSearch){
			setLastSearch(_search);
			//googleMapsClient.geocode({address=search}).asPromise()
			await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${_search}&key=${key}&sensor=false`)
				.then(({data: {results}}) => {
					if(results.length > 0){
						setRecord({
							...record,
							coordinate: {
								address: (coordinate && coordinate.address) ? coordinate.address : '' , 
								position: results[0].geometry.location
							}
						});
					}
				});
		}
	};

	useInterval(route, 3000, 5);

	return <div>
		{preview && 
			<div style={{width: 300, border: "1px solid #ddd", padding: 10, 
				position: 'absolute', marginLeft: 510}}>
				<h6 style={{position: 'absolute', marginTop: - 50}}>Ön İzleme</h6>
				<p style={{whiteSpace: "pre-wrap"}}>Adres: {(coordinate && coordinate.address) || ''}</p>
				<br/>
				<a>Tel: {record.phone}</a>
			</div>
		}

		<Alert message="Adreslerin genelden özele dogru yazılması en dogru konumu verir." banner />
		<Input.TextArea value={(coordinate && coordinate.address) || ''} disabled={disabled}
			required={required} style={{width: 500}} 
			onChange={e => setRecord({...record, 
				[name]: {...coordinate, address: e.target.value }})
			} />

		{!disabled && <div>
			
			<Input value={search} onChange={e => setSearch(e.target.value)} 
				addonAfter={<SearchOutlined />}
				style={{width: 500}} placeholder="Konum ara" />
			<LocationPicker
				containerElement={ <div style={ {height: '100%'} } /> }
				mapElement={ <div style={ {height: 300, width: 500} } /> }
				defaultPosition={(coordinate  && coordinate.position) ? coordinate.position : {lat: 40.9637815, lng: 28.7239388}}
				onChange={x => {
					setRecord({
						...record,
						coordinate: {
							address: (coordinate && coordinate.address) ? coordinate.address : '' , 
							position:x.position
						}
					});
				}}
			/>
		</div>}
		</div>; 
};