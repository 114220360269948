import React, { useState, useEffect } from 'react';
import { Upload} from 'antd';
import { PlusOutlined,LoadingOutlined} from '@ant-design/icons';
import api from '../service/index'

export default (props) => {
	let { record, setRecord, name } = props;
	let [file, setFile] = useState([]);
	let [imageUrl, setImageUrl] = useState(false);
	let [loading, setLoading] = useState(false)
	let [uploadStart, setUploadStart] = useState(false)
	
	useEffect(() => {
		if(record[name]) {
			setImageUrl(record[name]);
		}
	}, []);

	let handleChange = info => {
		if(!info.event && uploadStart === false) {
			setUploadStart(true)
			const formData = new FormData();
			formData.append('files_0', info.file.originFileObj)
			api.post("/api/upload", formData, { headers: { 'ContenType': 'multipart/form-data'} }).then(({ data: { result, result_message } }) => {
				if (result_message.type === 'success') {
					setFile(result[0]);
					setImageUrl(result[0].url);
					setRecord({...record, [name]:result[0].url})
					setUploadStart(false)
				}
				setLoading(false);
			});
		}
	  };

	let beforeUpload = () => { 
		setImageUrl(false)
		setLoading(true);
	}  
	const uploadButton = (
		<div>
		  {loading ? <LoadingOutlined /> : <PlusOutlined />}
		  <div style={{ marginTop: 8 }}>Upload</div>
		</div>
	);

	return <>
		<Upload
        name={name}
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
		beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl ? <img src={imageUrl} alt={name} style={{ width: '100%' }} /> : uploadButton}
      </Upload>
	</>
};
