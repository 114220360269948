import React, { useEffect, useState } from "reactn";
import { Row, Col, Form, Input, Button, Card, message, Popconfirm } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import moment from 'moment';
import { RightOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import api from '../../service/index'
import { Link } from 'react-router-dom';


const PagePublicHolidays = (props) => {
    let history = useHistory()
    let id = "publicHolidays"

    let [data, setData] = useState({});
    let [errors, setErrors] = useState([]);
    let [loading, setLoading] = useState(true);

    //onetime run
    useEffect(() => {
        get();
    }, []);

    let get = async () => {
        if (id) {
            await api.get("/rest/settings/" + id).then(({ data: { result, result_message } }) => {
                setData(result);
                setLoading(false)
            });
        } else {
            setLoading(false)
        }
    }

    let deleteRow = async (index) => {
        if (index > -1) {
            data.items.splice(index, 1);
        }
        data.id= id;
        api.put("/rest/settings/" + id, data).then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
                message.success("Kayıt başarılı bir şekilde silindi", 2);
                history.push('/settings/pagePublicHolidays')
            }
            else
                message.error("Kayıt silinirken bir hata oluştu.", 2);
        })
    }

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>Rezervasyon Ayarları</h1>
                </div>
                <div className="list-buttons">
                    <Link to={"/settings/pagePublicHolidays/" + id + "/add"}>
                        <Button type="light" icon={<PlusOutlined />} style={{ marginRight: "5px" }} size="large">Yeni Ekle</Button>
                    </Link>
                    <Link to="/settings/reservationAllSettings">
                        <Button type="light" icon={<LeftOutlined />} size="large">GERİ</Button>
                    </Link>
                </div>
            </div>
            <div className="form-wrap">
                {!loading &&
                    <Card title={id ? "Resmi Tatiller" : "Resmi Tatiller"}>
                        <Form layout="horizontal" size={"large"}>
                            <Card title={""}>
                                <div className="table-wrap" style={{ marginTop: 10 }} >
                                    <ul className="dragDrop">
                                        {data.items.map((item, index) => {
                                            return (
                                                <li>
                                                    <div >
                                                        <RightOutlined style={{ fontSize: 20 }} />
                                                        <span className="nameDrag">{moment(item.date).format('DD-MM-YYYY') + "  -  " + item.title}</span>
                                                    </div>
                                                    <div className="rightButtons">
                                                        <Link to={"/settings/pagePublicHolidays/" + id + "/edit/" + index}><Button type="primary" size="medium" > Düzenle </Button></Link>
                                                        <Popconfirm
                                                            onConfirm={() => deleteRow(index)} title="Silmeyi Onaylıyor musunuz?"
                                                            okText="Onayla" cancelText="Vazgeç">
                                                            <Button type="danger" icon={<DeleteOutlined />}>
                                                                Sil
                                                            </Button>
                                                        </Popconfirm>
                                                    </div>

                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </Card>
                        </Form>
                    </Card>
                }
            </div>
        </div>
    );
};



export default PagePublicHolidays;
