import React from 'react';
import { useGlobal } from "reactn";
import { Avatar, Dropdown, Menu } from 'antd';

import { Link } from 'react-router-dom';
import { SettingOutlined } from '@ant-design/icons'
import Cookies from 'js-cookie'
import { env } from '../app.config';

const HeaderBar = (props) => {

  let [, setToken] = useGlobal("token");
  let [, setModules] = useGlobal("modules");
  let [user, setUser] = useGlobal("user");

  let logout = (props) => {
    Cookies.remove('token')
    localStorage.removeItem('modules')
    setToken(null)
    setModules([])
    if (env.COMPANY === "ISBANK")
      window.location.href = env.REDIRECTURL
  }

  const menu = (
    <Menu className="head-menu">
      <Menu.Item>
        <a rel="noopener noreferrer" href="./adminInfo" > Admin Bilgileri </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={logout} danger>
        Log Out
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="topMenu">

      <div>
        {user && user.role === 'superAdmin' &&
          <Link to="/settings">
            <SettingOutlined className="settings" />
          </Link>
        }
      </div>

      <Dropdown overlay={menu} placement="bottomRight" arrow>
        <div><Avatar
          onClick={(e) => e.preventDefault()}
          className="ant-dropdown-link"
          src="/images/default-avatar.svg"
        />
        </div>
      </Dropdown>
    </div>
  );
};



export default HeaderBar;
