import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu,Input } from 'antd';
import { useEffect, useState, useGlobal } from "reactn";


const { Sider } = Layout;

const SideMenu = ({ testFunc }) => {
  const [collapsed, setCollapsed] = useGlobal('collapsed');
  let [modules] = useGlobal("modules");
  let logo = useGlobal("logo")[0];
  let [search, setSearch] = useState(false)
  let cpName = useGlobal("login_setting")[0];
  const { Search } = Input;
  var url = {
    backgroundImage: `url(${logo})`
  };

  function tabTitle(titleName) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    document.title= cpName + " - " + titleName;
  }

  const getMenu = () => {
if(search!==false){
  if (modules) {
    return modules.map(item => {
      if (item.type === "infos") {
        item.type = item._id;
      }
      if(item.name.toLowerCase().indexOf(search.toLowerCase())!==-1){
      return (<Menu.Item key={item.type} onClick={() => tabTitle(item.name) }>
        <Link to={"/" + item.type}>
          <img alt="" src={item.icon} width="40" />
          <span>{item.name}</span>
        </Link>
      </Menu.Item>);
           }})   
  }
}
else{
      if (modules) {
      return modules.map(item => {
        if (item.type === "infos") {
          item.type = item._id;
        }
        return (<Menu.Item key={item.type} onClick={() => tabTitle(item.name) }>
          <Link to={"/" + item.type}>
            <img alt="" src={item.icon} width="40" />
            <span>{item.name}</span>
          </Link>
        </Menu.Item>);
      })
    }
}

  }
  useEffect(() => {
    if (modules) {
        getMenu();
    }
}, [search])
  let onChange = async (e) => {
    setSearch(e.target.value);
    getMenu();
}
  return (
    <div>
    <Sider collapsible collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)} theme="light" width="260">
      <div className="logo-wrap">
        <div className="logo" style={url}></div>
      </div>
      <Search placeholder="Ara" onChange={onChange} style={{paddingLeft:'15px'}} onSearch={(v) => { setSearch(v); getMenu() }} />
      <Menu theme="light" defaultSelectedKeys={['1']} mode="inline">
        {getMenu()}
      </Menu>
    </Sider>
  </div>
  );
};


export default SideMenu;