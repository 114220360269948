import React from 'react'
import { Button } from 'antd'
import { Link } from 'react-router-dom'
import { HomeOutlined } from '@ant-design/icons'

let NotFoundPage = () => {
	return (
		<div style={{ marginTop: 50, textAlign: 'center', fontSize: 'xxx-large', fontWeight: 'bold' }} >
			<p>Aradığınız Sayfayı Bulamadık.</p>
			<div style={{ justifyContent: 'center', height: 'auto', alignItems: 'center', display: 'flex' }}>

				<img src="/images/404.png" alt="404" />
			</div>
			<Link to={"/postwall"}>
				<Button type='primary' shape="round" size='large' icon={<HomeOutlined />}> Anasayfaya Git </Button>
			</Link>
		</div>
	)
}
export default NotFoundPage