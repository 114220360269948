import React, { useEffect, useState } from "reactn";

import { Row,Col,Form, Input, Button, Card, message ,InputNumber,Switch} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";


import api from '../../service/index'
import { Link } from 'react-router-dom';
import TextArea from "antd/lib/input/TextArea";

const ReservationSettings = (props) => {
  let history = useHistory()
  let id = "reservationSettings"

  let [data, setData] = useState({});
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(true);

  //onetime run
  useEffect(() => {
    get();
  }, []);

  let get = async () => {
    if(id) {
      await api.get("/rest/settings/" + id).then(({ data: { result, result_message } }) => {
        setData(result);   
        setLoading(false)
      });
    } else {
      setLoading(false)
    }
  }
  
  let save = async () => {
      if(id) {
        api.put("/rest/settings/" + id, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success("Bilgiler güncellendi", 2);
            history.push('/settings')
          }
          else
            message.error("Kayıt eklenirken bir hata oluştu.", 2);
        })
      } else {
        api.post("/rest/settings", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success("Kayıt Başarılı bir şekilde eklendi.", 2);
            history.push('/settings')
          } else {
            message.error(result_message.message, 2);
          }
        })
      }       
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Reservation Settings</h1>
        </div>
        <div className="list-buttons">
          <Link to="/settings">
            <Button type="light" icon={<LeftOutlined />} size="large">GERİ</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        {!loading &&
          <Card title={id ? "Düzenle" : "Ekle"}>
            <Form layout="horizontal" size={"large"} onFinish={save}>


            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label="id"  help={errors._id} validateStatus={errors._id ? 'error' : 'success'}>
                  <Input name="_id" disabled={true} value={data._id} onChange={e => setData({ ...data, _id: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>
        
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item label="Başlangıç (Pazartesi)"  help={errors.whichMonday} validateStatus={errors.whichMonday ? 'error' : 'success'}>
                  <InputNumber name="whichMonday" value={data.whichMonday} onChange={v => setData({ ...data, whichMonday: v })} />
                  <br /><small>Rezervasyonların önümüzdeki kaçıncı Pazartesi başlayacağı.</small>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item label="Uzunluk (Gün)"  help={errors.reserveDays} validateStatus={errors.reserveDays ? 'error' : 'success'}>
                  <InputNumber name="reserveDays" value={data.reserveDays} onChange={v => setData({ ...data, reserveDays: v })} />
                  <br /><small>Başlangıç tarihinden itibaren kaç gün öteye rezervasyon yapılacağı.</small>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item label="Kapanış (Gün)"  help={errors.reservationClosingDay} validateStatus={errors.reservationClosingDay ? 'error' : 'success'}>
                  <InputNumber name="reservationClosingDay" value={data.reservationClosingDay} onChange={v => setData({ ...data, reservationClosingDay: v })} />
                  <br /><small>Başlangıç tarihinden kaç gün önce rezervasyonların kapanacağı.</small>
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                  <ul>
                    <p><b>Başlangıç</b></p>
                    <li><b>Örn:</b> Başlangıç parametresi 1 olduğunda önümüzdeki ilk pazartesiden itibaren rezervasyon yapılabilecek.</li>
                    <li><b>Örn:</b> Başlangıç parametresi 2 olduğunda önümüzdeki ikinci pazartesiden itibaren rezervasyon yapılabilecek.</li>
                    <br />
                    <p><b>Uzunluk</b></p>
                    <li><b>Örn:</b> Uzunluk parametresi 5 olduğunda Pazartesinden Cumaya kadar Rezervasyon işlemi açık olur.</li>
                    <li><b>Örn:</b> Uzunluk parametresi 7 olduğunda Pazartesinden diğer Pazartesiye kadar Rezervasyon işlemi açık olur.</li>
                    <br />
                    <p><b>Kapanış</b></p>
                    <li><b>Örn:</b> Kapanış parametresi 1 olduğunda başlangıç günü olan Pazartesinden bir gün önce, Pazar günü rezervasyon işelemleri kapanır.</li>
                    <li><b>Örn:</b> Kapanış parametresi 2 olduğunda başlangıç günü olan Pazartesinden iki gün önce, Cumartesi günü rezervasyon işelemleri kapanır.</li>
                  </ul>
                </Col>
              </Row>


            <Row direction="row">
              <Col span={24}>
              <Form.Item>
                  <Button type="primary" htmlType="submit" size="large" block > KAYDET </Button>
              </Form.Item>
              </Col>
            </Row>
           
            </Form>
          </Card>
        }
      </div>
    </div>
  );
};



export default ReservationSettings;
