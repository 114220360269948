import React, { useEffect, useState } from 'reactn';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import {
  GroupOutlined,
  LeftOutlined,
  UngroupOutlined,
  RadiusSettingOutlined,
  FileDoneOutlined,
} from '@ant-design/icons';
import api from '../../service/index';
import qs from 'qs';

const ReservationAllSettings = (props) => {
  const initialDnDState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: [],
  };

  const [dragAndDrop, setDragAndDrop] = useState(initialDnDState);
  let [modules, setModules] = useState([]);

  let _params = { sort: 'order', page: 1, pageSize: 100 };

  let getResponse = async () => {
    await api
      .get(`/rest/settings?${qs.stringify(_params)}`)
      .then(({ data }) => {
        setModules(data && data.result && data.result.rows);
      });
  };

  useEffect(() => {
    getResponse();
  }, []);

  React.useEffect(() => {}, [dragAndDrop]);

  React.useEffect(() => {}, [modules]);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  let style = {
    fontSize: 15,
    'padding-right': 30,
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Rezervasyon Ayarları</h1>
        </div>
        <div className="list-buttons">
          <Link to="/place">
            <Button type="light" icon={<LeftOutlined />} size="large">
              GERİ
            </Button>
          </Link>
        </div>
      </div>
      <div className="table-wrap">
        <ul className="dragDrop">
          <div class="row">
            <hr />
            <div>
              <RadiusSettingOutlined style={{ fontSize: 20 }} />
              <b style={style}>
                <span> Rezervasyon Gün Ayarları</span>
              </b>
            </div>
            <hr />
            <ul>
              <li>
                <div>
                  <RadiusSettingOutlined style={{ fontSize: 20 }} />
                  <span className="nameDrag">Genel</span>
                </div>
                <div className="rightButtons">
                  <Link to={'/settings/pageReservationSettings'}>
                    <Button type="primary" size="medium" onClick={scrollTop}>
                      {' '}
                      Düzenle{' '}
                    </Button>
                  </Link>
                </div>
              </li>
              <li>
                <div>
                  <RadiusSettingOutlined style={{ fontSize: 20 }} />
                  <span className="nameDrag">Şube</span>
                </div>
                <div className="rightButtons">
                  <Link to={'/settings/pageReservationSettingsBranch'}>
                    <Button type="primary" size="medium" onClick={scrollTop}>
                      {' '}
                      Düzenle{' '}
                    </Button>
                  </Link>
                </div>
              </li>
            </ul>
          </div>

          <li>
            <div>
              <GroupOutlined style={{ fontSize: 20 }} />
              <span className="nameDrag">Resmi Tatiller</span>
            </div>
            <div className="rightButtons">
              <Link to={'/settings/pagePublicHolidays'}>
                <Button type="primary" size="medium" onClick={scrollTop}>
                  {' '}
                  Düzenle{' '}
                </Button>
              </Link>
            </div>
          </li>
          <li>
            <div>
              <UngroupOutlined style={{ fontSize: 20 }} />
              <span className="nameDrag">Bölümler</span>
            </div>
            <div className="rightButtons">
              <Link to={'/settings/pageReservationDepartments'}>
                <Button type="primary" size="medium" onClick={scrollTop}>
                  {' '}
                  Düzenle{' '}
                </Button>
              </Link>
            </div>
          </li>

          <li>
            <div>
              <FileDoneOutlined style={{ fontSize: 20 }} />
              <span className="nameDrag">KVKK Ayarları</span>
            </div>
            <div className="rightButtons">
              <Link to={'/settings/pageReservationKVKKSettings'}>
                <Button type="primary" size="medium" onClick={scrollTop}>
                  {' '}
                  Düzenle{' '}
                </Button>
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ReservationAllSettings;
