
export {default as Dashboard} from './Dashboard';
export {default as RedirectComp } from './RedirectComp';
{/* Settings */}
export {default as ReservationSettings} from './Settings/ReservationSettings';
export {default as ReservationSettingsBranch} from './Settings/ReservationSettingsBranch';
export {default as PageReservationSettings} from './Settings/PageReservationSettings';
export {default as PageReservationSettingsBranch} from './Settings/PageReservationSettingsBranch';

export {default as PagePublicHolidays} from './Settings/PagePublicHolidays';
export {default as PagePublicHolidaysDetail} from './Settings/PagePublicHolidaysDetail';
export {default as ReservationAllSettings} from './Settings/ReservationAllSettings';
export {default as PageReservationDepartments} from './Settings/PageReservationDepartments';
export {default as PageReservationDepartmentsDetail} from './Settings/PageReservationDepartmentsDetail';
export {default as ReservationKVKKSettings} from './Settings/ReservationKVKKSettings';
export {default as PageReservationKVKKSettings} from './Settings/PageReservationKVKKSettings';
{/* Settings */}

export {default as NotFoundPage} from './404';

export {default as Place} from './Place';
export {default as PlaceDetail} from './PlaceDetail';

export {default as Plan} from './Plan';
export {default as PlanDetail} from './PlanDetail';

export {default as PlanCoordinates} from './PlanCoordinates';

export {default as PlanReservation} from './PlanReservation';
export {default as PlanReservationDetail} from './PlanReservationDetail';

export {default as PlanReservationReport} from './PlanReservationReport';
export {default as PlaceReport} from './PlaceReport'

export {default as IsbankAttendee} from './IsbankAttendee';
export {default as IsbankAttendeeDetail} from './IsbankAttendeeDetail';