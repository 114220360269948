import React, { useEffect, useState } from "reactn";
import { useParams } from 'react-router-dom'
import { Row, Col, Form, Input, Button, Card, message, InputNumber } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';
import { useCallback } from "react";
import api from '../../service/index'

const PageReservationDepartmentsDetail = (props) => {
    let params = useParams()
    let history = useHistory()
    let id = params.id !== "add" ? params.id : false;

    let newRecord = {
        name: null,
        order: 0,
    }

    let [data, setData] = useState(id ? {} : newRecord);
    let [errors, setErrors] = useState([]);
    let [loading, setLoading] = useState(id ? true : false);
    let [validationCheck, setValidationCheck] = useState(false)

    //onetime run
    useEffect(() => {
        if (id) {
            api.get("/rest/reservationDepartments/" + id).then(({ data: { result, result_message } }) => {
                setData(result);
                setLoading(false)
            });
        } else {

            setLoading(false)
        }
    }, [id]);

    let validate = useCallback(() => {
        let errors = {};

        if (data.name === null)
            errors.name = 'Zorunlu Alan!'

        errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
        errors.hasError = errors.all.length > 0;
        return errors;
    }, [data]);

    useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);

    let save = async () => {
        setValidationCheck(true)
        let err = validate()
        if (err.hasError) {
            setErrors(err)
            window.scrollTo({ top: 20, behavior: 'smooth' });
        }
        else {
            data.id = id;
            if (id) {
                api.put("/rest/reservationDepartments/" + id, data).then(({ data: { result, result_message } }) => {
                    if (result_message.type === 'success') {
                        message.success("Bilgiler güncellendi", 2);
                        history.push('/settings/pageReservationDepartments')
                    }
                    else
                        message.error("Kayıt güncellenirken bir hata oluştu.", 2);
                })

            } else {
                api.post("/rest/reservationDepartments", data).then(({ data: { result, result_message } }) => {
                    if (result_message.type === 'success') {
                        message.success("Kayıt Başarılı bir şekilde eklendi.", 2);
                        history.push('/settings/pageReservationDepartments')
                    } else {
                        message.error(result_message.message, 2);
                    }
                })
            }
        }
    };

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>Rezervasyon Ayarları</h1>
                </div>
                <div className="list-buttons">
                    <Link to="/settings/pageReservationDepartments">
                        <Button type="light" icon={<LeftOutlined />} size="large">GERİ</Button>
                    </Link>
                </div>
            </div>
            <div className="form-wrap">
                <Card title={id ? "Bölüm Düzenle" : "Bölüm Ekle"} loading={loading}>
                    <Form layout="horizontal" size={"large"} onFinish={save}>

                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                <Form.Item label="İsim" required help={errors.name} validateStatus={errors.name ? 'error' : 'success'}>
                                    <Input name="name" value={data.name} onChange={e => setData({ ...data, name: e.target.value })} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item label="Sıra">
                                    <InputNumber name="order" value={data.order} onChange={v => setData({ ...data, order: v })} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row direction="row">
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" disabled={loading} htmlType="submit" size="large" block > KAYDET </Button>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
                </Card>
            </div>
        </div>
    );
};

export default PageReservationDepartmentsDetail;
