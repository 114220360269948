import React, { useEffect, useState, useGlobal } from "reactn";
import { useParams } from 'react-router-dom'
import { Row, Col, Form, Input, Button, Switch, Card, message, DatePicker, Popconfirm, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { ReservationUsersPicker, ReservationCoordinatesPicker } from '../components';
import { Link } from 'react-router-dom';
import { useCallback } from "react";
import Parse from 'parse';
import moment from 'moment';
import dayjs from "dayjs";
import PlanCoordinates from './PlanCoordinates';

const PlaceDetail = (props) => {
    let params = useParams()
    let history = useHistory()
    let id = params.id !== "add" ? params.id : false;

    let [customization] = useGlobal("customization")
    let parseServerURL = customization.parseServerURL
    let parseAppId = customization.parseAppId

    Parse.initialize(parseAppId)
    Parse.serverURL = parseServerURL
    let Place = Parse.Object.extend("Place");
    let Plan = Parse.Object.extend("Plan");
    let Reservation = Parse.Object.extend("Reservation");
    let User = Parse.Object.extend("User");
    let PlanCoordinates = Parse.Object.extend("PlanCoordinates");

    let newRecord = {
    }

    let [data, setData] = useState(id ? {} : newRecord);
    let [modal, setModal] = useState(null);
    let [place, setPlace] = useState(id ? {} : newRecord);
    let [plan, setPlan] = useState(id ? {} : newRecord);
    let [errors, setErrors] = useState([]);
    let [loading, setLoading] = useState(id ? true : false);
    let [validationCheck, setValidationCheck] = useState(false)

    let [modules] = useGlobal("modules");
    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }

    //onetime run
    useEffect(() => {
        if (modules && id) {
            setLoading(true)
            let query = new Parse.Query(Reservation);
            query.get(id).then((reservation) => {
                let res = { id: reservation.id, ...reservation.attributes, users: reservation.attributes.users.id }
                setData(res);
                setLoading(false)
            }, (error) => {
                message.error("Kayıt bilgilerine ulaşılamadı.", 2);
            });


        }
    }, [id]);

    useEffect(() => {
        if (data) {
            let query = new Parse.Query(Plan);
            query.get(data.floorId).then((plann) => {
                let plan = { id: plann.id, ...plann.attributes }
                setPlan(plan);
                let queryPlace = new Parse.Query(Place);
                queryPlace.get(plan.placeId.id).then((placee) => {
                    let place = { id: placee.id, ...placee.attributes }
                    setPlace(place);
                }, (error) => {
                });
            }, (error) => {
            });
        }
    }, [data]);

    let validate = useCallback(() => {
        let errors = {};
        if (data.name === null)
            errors.name = 'Zorunlu Alan!'

        errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
        errors.hasError = errors.all.length > 0;
        return errors;
    }, [data]);

    useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);

    let saveOk = async () => {
        setValidationCheck(true)
        let err = validate()
        if (err.hasError) {
            setErrors(err)
            window.scrollTo({ top: 20, behavior: 'smooth' });
        }
        else {
            if (id) {
                let user = {}
                let planCoordinates = {}

                let queryUser = new Parse.Query(User);
                queryUser.equalTo("objectId", data.users);
                await queryUser.first().then((object) => {
                    user = object
                }, (error) => {
                    message.error("Kullanıcı bilgilerine ulaşılamadı.", 2);
                });

                let queryCoordinates = new Parse.Query(PlanCoordinates);
                queryCoordinates.equalTo("objectId", data.itemId);
                await queryCoordinates.first().then((object) => {
                    planCoordinates = object
                }, (error) => {
                    message.error("Oturma alanı bilgilerine ulaşılamadı.", 2);
                });

                data.users = user
                if (data.date._d) {
                    data.date = data.date._d
                }

                let res = []
                let d = dayjs(data.date).format('DD-MM-YYYY').toString()
                let queryRes = new Parse.Query(Reservation);
                queryRes.equalTo("itemId", data.itemId);
                await queryRes.find().then((object) => {
                    res = object.filter(x => dayjs(x.get("date")).format('DD-MM-YYYY').toString() === d && x.id !== data.id);
                    res.forEach(element => {
                        element.destroy().then((object) => {
                            message.success("Diğer kayıtlar  silindi", 2);
                        }, (error) => {
                            message.error("Kayıt silinemedi", 2);
                        });

                    });
                }, (error) => {
                    message.error("Kullanıcı bilgilerine ulaşılamadı.", 2);
                });

                let query = new Parse.Query(Reservation);
                query.equalTo("objectId", id);
                await query.first().then((object) => {
                    data.PlanCoordinates = planCoordinates
                    data.isReservationLunch = false
                    data.reservationTitle = place.name + "/" + plan.title + "/" + planCoordinates.attributes.name

                    object.save(data).then(() => {
                        message.success("Bilgiler güncellendi", 2);
                        history.push('/placeReport')
                    }, (error) => {
                        message.error("Kayıt düzünlenemedi", 2);
                    });

                }, (error) => {
                    message.error("Kayıt bilgilerine ulaşılamadı.", 2);
                });
            }
        }
    };

    let save = async () => {
        let user = ""
        let message = "Rezervasyon bilgileri değiştirilecek, onaylıyor muzunuz?"

        let res = []
        let d = dayjs(data.date).format('DD-MM-YYYY').toString()
        let queryRes = new Parse.Query(Reservation);
        queryRes.equalTo("itemId", data.itemId);
        await queryRes.find().then((object) => {
            res = object.filter(x => dayjs(x.get("date")).format('DD-MM-YYYY').toString() === d && x.id !== data.id);
            res.forEach(element => {
                if (element) {
                    let name = ["- " + element.attributes.users.attributes.name, element.attributes.users.attributes.lastName].filter(x => x).join(' ')
                    user = [user, name].filter(x => x).join(', ')
                }
            });
        }, (error) => {
            message.error("Kullanıcı bilgilerine ulaşılamadı.", 2);
        });

        if (user !== "") {
            message = d + " tarihinde, seçtiğiniz masada aşagıdaki kullanıcıya ait rezervasyon vardır. Devam ederseniz mevcut rezervasyon iptal oalcaktır. Yine de ilerlemek istiyor musunuz?"
        }


        setModal({ message: message, users: user })
    }

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""} Rezervasyon</h1>
                </div>
                <div className="list-buttons">
                    <Link to="/placeReport">
                        <Button type="light" icon={<LeftOutlined />} size="large">GERİ</Button>
                    </Link>
                </div>
            </div>
            <div className="form-wrap">
                <Card title={id ? "Düzenle" : "Ekle"} loading={loading}>
                    <Form layout="horizontal" size={"large"} onFinish={save}>

                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                <Form.Item label="Bina">
                                    <Input disabled={true} name="Bina" value={place.name} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                <Form.Item label="Plan">
                                    <Input disabled={true} name="Plan" value={plan.title} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                <Form.Item label="Oturma Alanı">
                                    <ReservationCoordinatesPicker record={data} setRecord={setData} name="itemId" planId={data.floorId} parseServerURL={parseServerURL} parseAppId={parseAppId} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                <Form.Item label="Kullanıcı">
                                    <ReservationUsersPicker record={data} setRecord={setData} name="users" parseServerURL={parseServerURL} parseAppId={parseAppId} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item label="Rezervasyon Tarihi:" help={errors.date} validateStatus={errors.date ? 'error' : 'success'}>
                                    <DatePicker defaultValue={() => moment(data.date)} onChange={v => setData({ ...data, date: v })} format='DD/MM/YYYY' showTime={true} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item label="Yemek" help={errors.lunch} validateStatus={errors.lunch ? 'error' : 'success'}>
                                    <Switch checked={data.lunch ? true : false} checkedChildren="Aktif" unCheckedChildren="Pasif" onChange={v => setData({ ...data, lunch: v })} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item label="Servis" help={errors.service} validateStatus={errors.service ? 'error' : 'success'}>
                                    <Switch checked={data.service ? true : false} checkedChildren="Aktif" unCheckedChildren="Pasif" onChange={v => setData({ ...data, service: v })} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row direction="row">
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" disabled={loading} htmlType="submit" size="large" block > KAYDET </Button>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
                </Card>
            </div>
            {modal &&
                <div>
                    <Modal
                        title={""}
                        visible={true}
                        onOk={() => saveOk()}
                        onCancel={() => setModal(null)}>
                        <Card title={"Uyarı"} loading={modal ? false : true} >
                            <p>{modal.message}</p>
                            <p>{modal.users}</p>


                        </Card>
                    </Modal>
                </div>
            }
        </div>
    );
};

export default PlaceDetail;
