import React, { useEffect } from 'react'
import { useGlobal } from 'reactn'
import { useParams, useHistory } from 'react-router-dom'
import axios from 'axios'
import { env } from '../app.config'
import Cookies from 'js-cookie'
import api from './../service';
import qs from 'qs';

const isbankAuth = () => {
    let query = {
        "lang": "TR",
        "adminShow": "true"
    }

    let { id, key } = useParams()
    let history = useHistory()
    let [token, setToken] = useGlobal("token");
    let [modules, setModules] = useGlobal("modules");
    let [user, setUser] = useGlobal("user");
    let [settings, setSettings] = useGlobal("settings");
    let [customization, setCustomization] = useGlobal("customization");
    let [langs, setLangs] = useGlobal("lang");
    let [pdfIcon, setPdfIcon] = useGlobal("pdf");
    let [logo, setLogo] = useGlobal("logo");
    let [login_setting, setLogin_setting] = useGlobal("login_setting");

    let oauthLogin = async () => {

     
        axios.get(env.BASEAPIURL + "api/me", {
            headers: {
               Authorization: "Bearer " + key,
            }
        }).then(async ({ data: { result, result_message } }) => {
            console.log("result",result);
            if (result) {
                setToken(key);
                setUser(result);
                localStorage.setItem("user", JSON.stringify(result));
                Cookies.set("token", key)
                api.setHeader('Authorization', "Bearer " + key);
                api.get(`/rest/settings?${qs.stringify()}`).then(({ data }) => {
                    setSettings(data.result.rows);
                    if (data.result.rows) {
                        data.result.rows.forEach(element => {
                            if (element._id === "customization") {
                                setCustomization(element)
                                localStorage.setItem("customization", JSON.stringify(element));
                                setLogo(element.cmsLogo)
                                localStorage.setItem("logo", JSON.stringify(element.cmsLogo));
                            }
                            if (element._id === "login_setting") {
                                setLogin_setting(element.companyName)
                                localStorage.setItem("login_setting", JSON.stringify(element.companyName));
                            }
                            if (element._id === "supportedLangs") {
                                setLangs(element.items)
                                localStorage.setItem("langs", JSON.stringify(element.items[0]));
                            }
                            if (element._id === "surveyWithMediaIcons") {
                                setPdfIcon(element.pdf)
                                localStorage.setItem("pdfIcon", JSON.stringify(element.pdf));
                            }
                        });
                    }
                    localStorage.setItem("settings", JSON.stringify(data.result.rows));
                })

                await api.get(`/rest/modules?${qs.stringify(query)}`).then(({ data }) => {

                    setModules(data.result.rows);
                    localStorage.setItem("modules", JSON.stringify(data.result.rows));
                    history.push("/" + data.result.rows[0]._id);
                })

            } else {
                window.location.href = env.REDIRECTURL;
            }
             
        })
    }

    useEffect(() => {
        if (!id || id === "undefined" || !key || key === "undefined"){
                window.location.href = env.REDIRECTURL;
        } else {
            oauthLogin()
        }
    }, [])

    return (
        <div></div>
    )
}

export default isbankAuth
