import React, { useGlobal } from "reactn";

const Login = (props) => {
  let [modules,setModules] = useGlobal("modules");
  if(modules){
    let module = modules[0].refId
    props.history.push(module);
  }
  else
    props.history.push("login");

  return (
    <div className = "login-wrap"> </div>
  );

};
export default Login;
