import React, { useEffect, useState, useGlobal } from 'reactn';
import {
  Table,
  Space,
  Button,
  DatePicker,
  Row,
  Col,
  Select,
  Checkbox,
  Popconfirm,
  message,
} from 'antd';
import Parse from 'parse';
import {
  CheckOutlined,
  CloseOutlined,
  Loading3QuartersOutlined,
  DownloadOutlined,
  SearchOutlined,
  LeftOutlined,
  RightOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ExportJsonExcel from 'js-export-excel';
import api from '../service/index';
import qs from 'qs';
const { RangePicker } = DatePicker;

const Place = (props) => {
  const [stData, setStData] = useState({
    buildings: [],
  });
  let [totalCount, setTotalCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [loading, setLoading] = useState(false);

  let [customization] = useGlobal('customization');
  let parseServerURL = customization && customization.parseServerURL;
  let parseAppId = customization && customization.parseAppId;
  let [reservationPageData, setReservationPageData] = useState();

  let [placeData, setPlaceData] = useState();
  let [planData, setPlanData] = useState();
  let [planCoordinateData, setPlanCoordinateData] = useState();
  let [userData, setUserData] = useState();
  let [departmentData, setDepartmentData] = useState();
  let date = moment(new Date()).format('YYYY.MM.DD');
  let date5 = moment(new Date()).add(7, 'days').format('YYYY.MM.DD');
  let [filters, setFilters] = useState({
    departments: null,
    planCoordinates: [],
    place: [],
    plan: [],
    users: [],
    startDate: moment(date + ' 00:00'),
    endDate: moment(date5 + ' 23:59'),
    lunch: false,
    service: false,
  });
  Parse.initialize(parseAppId);
  Parse.serverURL = parseServerURL;

  let Reservation = Parse.Object.extend('Reservation');
  let Place = Parse.Object.extend('Place');
  let Plan = Parse.Object.extend('Plan');
  let PlanCoordinates = Parse.Object.extend('PlanCoordinates');
  let User = Parse.Object.extend('User');

  let reservationQuery = new Parse.Query(Reservation);
  reservationQuery.include('users');
  let reservationPageQuery = new Parse.Query(Reservation);
  reservationPageQuery.include('users');
  let placeQuery = new Parse.Query(Place);
  let planQuery = new Parse.Query(Plan);
  let planCoordinatesQuery = new Parse.Query(PlanCoordinates);
  let userQuery = new Parse.Query(User);

  const columns = [
    {
      title: 'Bina',
      dataIndex: 'placeName',
      key: 'placeName',
    },
    {
      title: 'Kat',
      dataIndex: 'planName',
      key: 'PlanName',
    },
    {
      title: 'Masa',
      dataIndex: 'PlanCoordinatesName',
      key: 'PlanCoordinatesName',
    },
    {
      title: 'Kullanıcı id',
      dataIndex: 'userId',
      key: 'userId',
    },
    {
      title: 'Kullanıcı Adı',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: 'Kullanıcı Bölümü',
      dataIndex: 'userPosition',
      key: 'userPosition',
    },
    {
      title: 'Yemekli mi ?',
      dataIndex: 'lunch',
      key: 'lunch',
      align: 'center',
      render: (lunch) => {
        return <div>{lunch ? <CheckOutlined /> : <CloseOutlined />}</div>;
      },
    },
    {
      title: 'Servis Hizmeti',
      dataIndex: 'service',
      key: 'service',
      align: 'center',
      render: (service) => {
        return <div>{service ? <CheckOutlined /> : <CloseOutlined />}</div>;
      },
    },
    {
      title: 'Tarih',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.date - b.date,
      render: (text) => moment(text).format('DD-MM-YYYY'),
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (id) => {
        return (
          <Space size="small">
            <Popconfirm
              onConfirm={() => deleteRow(id)}
              title="Sil"
              okText="Silmeyi onaylıyor musunuz?"
              cancelText="Vazgeç"
            >
              <Button type="danger" icon={<DeleteOutlined />}>
                Sil
              </Button>
            </Popconfirm>
            <Link to={`/planReservation/${id}/`}>
              <Button>
                {' '}
                Detay <RightOutlined />{' '}
              </Button>
            </Link>
          </Space>
        );
      },
    },
  ];

  const { Option } = Select;

  useEffect(() => {
    setLoading(true);
    getUsers();
    getPlaces();
    getPlans();
    getPlanCoordinates();
    getDepartments();
    applyFilters('page');
  }, []);

  useEffect(() => {
    applyFilters('page');
    setLoading(true);
  }, [currentPage]);

  let getPlaces = async () => {
    placeQuery.limit(1000000000000);
    await placeQuery.find().then(
      (places) => {
        setPlaceData(
          places.map((place) => {
            return { ...place.attributes, id: place.id };
          }),
        );
      },
      (error) => {
        message.error('Kayıt bilgilerine ulaşılamadı.', 2);
      },
    );
  };

  let getPlans = async (e) => {
    planQuery.limit(1000000000000);
    await planQuery.find().then(
      (plans) => {
        const newPlans = plans.map((plan) => {
          return { ...plan.attributes, id: plan.id };
        });

        if (e && e.length > 0) {
          const newPlanData = newPlans.filter(
            (item) => e && e.includes(item.placeId.id),
          );
          setPlanData(newPlanData);
          // masaları filtreleme için
          getPlanCoordinates(newPlanData);
        } else {
          setPlanData(newPlans);
          // masaları filtreleme için
          getPlanCoordinates(newPlans);
        }
      },
      (error) => {
        message.error('Kayıt bilgilerine ulaşılamadı.', 2);
      },
    );
  };

  let getPlanCoordinates = async (floors) => {
    const floorIds = floors && floors.map((item) => (item.id ? item.id : item));

    planCoordinatesQuery.limit(1000000000000);

    await planCoordinatesQuery.find().then(
      (planCoordinates) => {
        if (floorIds && floorIds.length > 0) {
          const newData = planCoordinates.filter((item) =>
            floorIds.includes(item.attributes.planId.id),
          );

          setPlanCoordinateData(
            newData.map((planCoordinate) => {
              return {
                id: planCoordinate.id,
                name: planCoordinate.attributes.name,
                placeName: planCoordinate.attributes.planId.attributes.placeId
                  ? planCoordinate.attributes.planId.attributes.placeId
                      .attributes.name
                  : '',
                planName: planCoordinate.attributes.planId
                  ? planCoordinate.attributes.planId.attributes.title
                  : '',
              };
            }),
          );
        } else {
          setPlanCoordinateData(
            planCoordinates.map((planCoordinate) => {
              return {
                id: planCoordinate.id,
                name: planCoordinate ? planCoordinate.attributes.name : '',
                placeName: planCoordinate.attributes.planId.attributes.placeId
                  ? planCoordinate.attributes.planId.attributes.placeId
                      .attributes.name
                  : '',
                planName: planCoordinate.attributes.planId
                  ? planCoordinate.attributes.planId.attributes.title
                  : '',
              };
            }),
          );
        }

      },
      (error) => {
        message.error('Kayıt bilgilerine ulaşılamadı.', 2);
      },
    );
  };

  let getUsers = async () => {
    let _params = { page: 1, pageSize: 100000000 };
    await api
      .get(`/rest/attendees?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setUserData(data.result.rows);
        }
      });
    setLoading(false);
  };

  let getDepartments = async () => {
    let _params = { sort: 'order', page: 1, pageSize: 100000000 };
    await api
      .get(`/rest/reservationDepartments?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setDepartmentData(
            data.result.rows.map((department) => {
              return { ...department, id: department._id };
            }),
          );
        }
      });
  };

  let placeChange = async (e) => {
    // Binalar
    setStData({ ...stData, buildings: e });
    let pp = [];
    let query = new Parse.Query(Plan);
    query.containedIn('placeId', [e]);
    await query.find().then(
      (plans) => {
        plans.map((plan) => {
          pp.push(plan.id);
        });
        setFilters({ ...filters, place: pp });

        // katlar filtreleme için
        getPlans(e);
      },
      (error) => {
        message.error('Kayıt bilgilerine ulaşılamadı.', 2);
      },
    );
  };

  let planChange = (e) => {
    setStData({ ...stData, floors: e });
    getPlanCoordinates(e);
    setFilters({ ...filters, plan: e });
  };

  let planCoordinatesChange = (e) => {
    setStData({ ...stData, tables: e });
    setFilters({ ...filters, planCoordinates: e });
  };

  let userChange = (e) => {
    setStData({ ...stData, users: e });
    setFilters({ ...filters, users: e });
  };

  let departmentChange = async (e) => {
    setStData({ ...stData, departments: e });
    setFilters({ ...filters, departments: e });
  };

  let lunchChange = (e) => {
    setFilters({ ...filters, lunch: e.target.checked });
  };

  let serviceChange = (e) => {
    setFilters({ ...filters, service: e.target.checked });
  };

  let onChangeRangePicker = async (date, dateString) => {
    if (date !== null) {
      setFilters({
        ...filters,
        startDate: moment(dateString[0] + ' 00:00'),
        endDate: moment(dateString[1] + ' 23:59'),
      });
    } else {
      setFilters({ ...filters, startDate: null, endDate: null });
    }
  };

  let applyFilters = async (from) => {
    if (filters.place.length > 0) {
      reservationQuery.containedIn('floorId', filters.place);
      reservationPageQuery.containedIn('floorId', filters.place);
    }

    if (filters.plan.length > 0) {
      reservationQuery.containedIn('floorId', filters.plan);
      reservationPageQuery.containedIn('floorId', filters.plan);
    }

    if (filters.planCoordinates.length > 0) {
      reservationQuery.containedIn('PlanCoordinates', filters.planCoordinates);
      reservationPageQuery.containedIn(
        'PlanCoordinates',
        filters.planCoordinates,
      );
    }

    if (filters.users.length > 0) {
      reservationQuery.containedIn('userId', filters.users);
      reservationPageQuery.containedIn('userId', filters.users);
    }

    if (filters.departments !== null) {
      if (filters.departments !== 'Tüm Bölümler') {
        let pp = [];
        let _params = {
          page: 1,
          pageSize: 100000000,
          query: { position: filters.departments },
        };
        await api
          .get(`/rest/attendees?${qs.stringify(_params)}`, { _params })
          .then(({ data }) => {
            if (data.result) {
              data.result.rows.map((user) => {
                pp.push(user._id);
              });
              reservationQuery.containedIn('userId', pp);
              reservationPageQuery.containedIn('userId', pp);
            }
          });
      }
    }

    if (filters.service) {
      reservationQuery.equalTo('service', true);
      reservationPageQuery.equalTo('service', true);
    }

    if (filters.lunch) {
      reservationQuery.equalTo('lunch', true);
      reservationPageQuery.equalTo('lunch', true);
    }

    if (filters.startDate !== null && filters.endDate !== null) {
      reservationQuery.greaterThanOrEqualTo('date', filters.startDate._d);
      reservationQuery.lessThanOrEqualTo('date', filters.endDate._d);

      reservationPageQuery.greaterThanOrEqualTo('date', filters.startDate._d);
      reservationPageQuery.lessThanOrEqualTo('date', filters.endDate._d);
    }

    PageData(from);
    setLoading(true);
  };

  let PageData = async (from) => {
    reservationQuery.limit(1000000000000);
    await reservationQuery.count().then(function (result) {
      setTotalCount(result);
    });
    reservationPageQuery.limit(10);
    reservationPageQuery.skip((currentPage - 1) * 10);

    let reservationPageEdit = await reservationPageQuery.find();

    reservationPageEdit = Promise.all(
      reservationPageEdit.map(async function (p, key) {
        let title = p.attributes.reservationTitle.replace('Seyhan/', 'Seyhan-');
        let result = title.split('/');
        let users = p.attributes.users.toJSON();

        return {
          ...p.attributes,
          id: p.id,
          planName: result[1],
          placeName: result[0],
          PlanCoordinatesName: result[2],
          userName: users
            ? [users.name, users.lastname].filter((x) => x).join(' ')
            : '(silindi)',
          parseUserId: p.attributes.users.id || '',
          userId: users ? users.user_id : '',
          userPosition: users ? users.position : '',
          date: moment(p.attributes.date).format('YYYY-MM-DD') || '',
          isReservationLunch: p.attributes.isReservationLunch,
          service: p.attributes.service,
        };
      }),
    ).then(function (results) {
      setReservationPageData(results);
      if (from == 'page') {
        setLoading(false);
      }
    });
  };

  let deleteRow = async (item_id) => {
    let queryRes = new Parse.Query(Reservation);
    queryRes.equalTo('objectId', item_id);
    let object = await queryRes.first();

    object.destroy().then(
      () => {
        applyFilters('page');
      },
      (error) => {
        message.error('Kayıt silinemedi', 2);
      },
    );
  };

  let excellData = async () => {
    applyFilters('excel');
    let reservationEdit = await reservationQuery.find();

    var option = {};
    let dataTable = [];

    Promise.all(
      reservationEdit.map(async function (p, key) {
        let result = p.attributes.reservationTitle.split('/');
        let usersObject = p.attributes.users;

        if (p.attributes.users) {
          let users = p.attributes.users.toJSON();
          let obj = {
            Bina: result[0],
            Kat: result[1],
            Masa: result[2],
            Kullanıcı_id: users ? users.user_id : '',
            Kullanıcı: users
              ? [users.name, users.lastname].filter((x) => x).join(' ')
              : '(silindi)',
            Bölüm: users ? users.position : '',
            Yemek: p.attributes.lunch ? 'Var' : 'Yok',
            Servis: p.attributes.service ? 'Var' : 'Yok',
            Tarih: moment(p.attributes.date).format('YYYY-MM-DD') || '',
          };
          dataTable.push(obj);
        } else {
          console.log('p.attributes', p.attributes);
        }
      }),
    ).then(function () {
      let sheetAreas = [
        'Bina',
        'Kat',
        'Masa',
        'Tarih',
        'Kullanıcı_id',
        'Kullanıcı',
        'Bölüm',
        'Yemek',
        'Servis',
      ];
      option.fileName = 'Rapor';
      option.datas = [
        {
          sheetData: dataTable,
          sheetName: 'sheet',
          sheetFilter: sheetAreas,
          sheetHeader: [
            'Bina',
            'Kat',
            'Masa',
            'Tarih',
            'Kullanıcı_id',
            'Kullanıcı',
            'Bölüm',
            'Yemek',
            'Servis',
          ],
        },
      ];
      var toExcel = new ExportJsonExcel(option);
      toExcel.saveExcel();
      setLoading(false);
    });
  };

  let KVKKdownloadExcel = async () => {
    var option = {};
    let dataTable = [];

    userQuery.limit(1000000000);
    userQuery.equalTo('isKVKKAplied', true);
    userQuery.ascending('name');

    await userQuery
      .find()
      .then(function (results) {
        results.map((item, key) => {
          let user = item.attributes;

          let obj = {
            Kullanıcı_id: user.user_id,
            Kullanıcı: [user.name, user.lastName].filter((x) => x).join(' '),
            Bölüm: user.position,
            KVKK: user.isKVKKAplied ? 'Onayladı' : 'Onaylamadı',
          };

          dataTable.push(obj);
        });
      })
      .catch(function (error) {});

    let sheetAreas = ['Kullanıcı_id', 'Kullanıcı', 'Bölüm', 'KVKK'];
    option.fileName = 'KVKKRapor';
    option.datas = [
      {
        sheetData: dataTable,
        sheetName: 'sheet',
        sheetFilter: sheetAreas,
        sheetHeader: ['Kullanıcı_id', 'Kullanıcı', 'Bölüm', 'KVKK'],
      },
    ];
    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Rezervasyon Raporu</h1>
        </div>
        <div className="list-buttons">
          <Button
            size="large"
            onClick={KVKKdownloadExcel}
            style={{ marginRight: '5px' }}
            icon={<DownloadOutlined />}
          >
            {' '}
            KVKK Export Excel{' '}
          </Button>
          <Button
            size="large"
            onClick={excellData}
            style={{ marginRight: '5px' }}
            icon={<DownloadOutlined />}
          >
            {' '}
            Export Excel{' '}
          </Button>
          <Link to="/place">
            <Button type="light" icon={<LeftOutlined />} size="large">
              {' '}
              GERİ{' '}
            </Button>
          </Link>
        </div>
      </div>
      <div className="table-wrap">
        <Row
          gutter={{ sm: 24, md: 12, lg: 3 }}
          style={{ backgroundColor: '#fff', padding: '13px', marginTop: '5px' }}
        >
          <Col span={6}>
            <div>Binalar</div>
            <Select
              onChange={placeChange}
              name=""
              maxTagCount="responsive"
              mode="multiple"
              style={{ width: '95%' }}
              showSearch
              optionFilterProp="children"
            >
              {placeData &&
                placeData.map((place, i) => (
                  <Option value={place.id} key={i}>
                    {' '}
                    {place.name}{' '}
                  </Option>
                ))}
            </Select>
          </Col>
          <Col span={6}>
            <div>Katlar</div>
            <Select
              onChange={planChange}
              maxTagCount="responsive"
              mode="multiple"
              style={{ width: '95%' }}
              showSearch
              disabled={
                stData && stData.buildings && stData.buildings.length === 0
              }
              optionFilterProp="children"
            >
              {planData &&
                planData.map((plan, i) => (
                  <Option value={plan.id} key={i}>
                    {' '}
                    {plan.title} ({plan.placeId.attributes.name})
                  </Option>
                ))}
            </Select>
          </Col>
          <Col span={6}>
            <div>Masalar</div>
            <Select
              onChange={planCoordinatesChange}
              maxTagCount="responsive"
              mode="multiple"
              style={{ width: '95%' }}
              showSearch
              optionFilterProp="children"
            >
              {planCoordinateData &&
                planCoordinateData.map((pc, i) => (
                  <Option value={pc.id} key={i}>
                    {' '}
                    {pc.name} ({pc.placeName} / {pc.planName})
                  </Option>
                ))}
            </Select>
          </Col>
          <Col span={6}>
            <div>Kullanıcı id</div>
            <Select
              onChange={userChange}
              maxTagCount="responsive"
              disabled={
                stData &&
                ((stData.buildings && stData.buildings.length > 0) ||
                  (stData.floors && stData.floors.length > 0) ||
                  (stData.tables && stData.tables.length > 0))
              }
              mode="multiple"
              style={{ width: '95%' }}
              showSearch
              optionFilterProp="children"
            >
              {userData &&
                userData.map((users, i) => (
                  <Option value={users._id} key={i}>
                    {' '}
                    {users._id}
                  </Option>
                ))}
            </Select>
          </Col>
          <Col span={6}>
            <div>Kullanıcılar</div>
            <Select
              onChange={userChange}
              maxTagCount="responsive"
              mode="multiple"
              style={{ width: '95%' }}
              showSearch
              optionFilterProp="children"
            >
              {userData &&
                userData.map((users, i) => (
                  <Option value={users._id} key={i}>
                    {' '}
                    {users.name} {users.lastname}{' '}
                  </Option>
                ))}
            </Select>
          </Col>
          <Col span={6}>
            <div>Bölümler</div>
            <Select
              onChange={departmentChange}
              maxTagCount="responsive"
              mode="single"
              style={{ width: '95%' }}
              showSearch
              optionFilterProp="children"
            >
              {departmentData &&
                departmentData.map((department, i) => (
                  <Option value={department.name} key={i}>
                    {' '}
                    {department.name}{' '}
                  </Option>
                ))}
            </Select>
          </Col>
          <Col span={6}>
            <div>Tarih</div>
            <RangePicker
              onChange={onChangeRangePicker}
              style={{ width: '95%' }}
              defaultValue={[moment(date + ' 00:00'), moment(date5 + ' 23:59')]}
            />
          </Col>
          <Col
            span={2}
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-start',
            }}
          >
            <Checkbox defaultChecked={false} onChange={lunchChange}>
              {' '}
              Yemek{' '}
            </Checkbox>
          </Col>
          <Col
            span={2}
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-start',
            }}
          >
            <Checkbox defaultChecked={false} onChange={serviceChange}>
              {' '}
              Servis{' '}
            </Checkbox>
          </Col>
          <Col
            span={2}
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
            }}
          >
            <Button
              type="primary"
              icon={<SearchOutlined />}
              style={{ marginRight: '5px' }}
              size="large"
              onClick={() => applyFilters('page')}
              disabled={loading}
            >
              {' '}
              Filtrele{' '}
            </Button>
          </Col>
        </Row>
        <Table
          dataSource={reservationPageData}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          pagination={{
            onChange: setCurrentPage,
            total: totalCount,
          }}
        />
      </div>
    </div>
  );
};

export default Place;
