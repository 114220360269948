module.exports = {
	
	env: {
		API: "https://dev-isbank-cmsapi.iciletisim.app/",
		PORT: "8002",
		COMPANY:"ISBANKs",
		BASEAPIURL: "https://dev-isbank-cmsapi.iciletisim.app/",
	}
	
	// env: {
	// 	API: "https://ik.isim.isbank.com.tr/cmsapi",
	//  	PORT: "8002",
	//  	COMPANY:"ISBANKS",
	//  	BASEAPIURL: "https://ik.isim.isbank.com.tr/cmsapi/",
	//  }
}	
